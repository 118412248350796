/* eslint-disable */
/**
 * project: user
 * fileName: utils
 * Created by 徐俊 on 2020/09/15
 * Copyright © 2020 Enfry Ltd. All rights reserved.
 */
import dayjs from "dayjs";
import { selectService } from "@/api/select";

function getToday(dd) {
  const d = dd || new Date();
  const m = (`00${d.getMonth() + 1}`);
  const r = (`00${d.getDate()}`);
  return `${d.getFullYear()}-${m.substr(m.length - 2)}-${r.substr(r.length - 2)}`;
}
Date.prototype.addYear = function(num) {
  if (!num) {
    return this;
  }
  num = Number((`${num}`).replace(/,/g, ""));
  const tempDate = this.getDate();
  this.setYear(this.getFullYear() + num);
  if (tempDate != this.getDate()) {
    this.setDate(0);
  }
  return this;
};

Date.prototype.addMonth = function(num) {
  if (!num) {
    return this;
  }
  num = Number((`${num}`).replace(/,/g, ""));
  const tempDate = this.getDate();
  this.setMonth(this.getMonth() + num);
  if (tempDate != this.getDate()) {
    this.setDate(0);
  }
  return this;
};
Date.prototype.addDate = function(num) {
  if (!num) {
    return this;
  }
  num = Number((`${num}`).replace(/,/g, ""));
  this.setDate(this.getDate() + num);
  return this;
};
const Utils = {
  /**
  * 得到上季度的起始日期
  * year 这个年应该是运算后得到的当前本季度的年份
  * month 这个应该是运算后得到的当前季度的开始月份
  * */
  getPriorSeasonFirstDay(year, month) {
    const spring = 0; // 春
    const summer = 3; // 夏
    const fall = 6; // 秋
    const winter = 9; // 冬
    // 月份从0-11
    switch (month) { // 季度的其实月份
      case spring:
        // 如果是第一季度则应该到去年的冬季
        year--;
        month = winter;
        break;
      case summer:
        month = spring;
        break;
      case fall:
        month = summer;
        break;
      case winter:
        month = fall;
        break;
    }
    return new Date(year, month, 1);
  },
  /**
  * 得到上季度的起止日期
  * * */
  getPreviousSeason() {
    // 获取当前时间
    const currentDate = new Date();
    // 获得当前月份0-11
    const currentMonth = this.getQuarterSeasonStartMonth(currentDate.getMonth());
    // 获得当前年份4位年
    const currentYear = currentDate.getFullYear();
    // 上季度的第一天
    const priorSeasonFirstDay = this.getPriorSeasonFirstDay(currentYear, currentMonth);
    // 上季度的最后一天
    const priorSeasonLastDay = new Date(priorSeasonFirstDay.getFullYear(), priorSeasonFirstDay.getMonth() + 2, this.getMonthDays(priorSeasonFirstDay.getFullYear(), priorSeasonFirstDay.getMonth() + 2));
    return [priorSeasonFirstDay, priorSeasonLastDay];
  },
  getCurrentSeason() {
    // 获取当前时间
    const currentDate = new Date();
    // 获得当前月份0-11
    const currentMonth = currentDate.getMonth();
    // 获得当前年份4位年
    const currentYear = currentDate.getFullYear();
    // 获得本季度开始月份
    const quarterSeasonStartMonth = this.getQuarterSeasonStartMonth(currentMonth);
    // 获得本季度结束月份
    const quarterSeasonEndMonth = quarterSeasonStartMonth + 2;
    // 获得本季度开始的日期
    const quarterSeasonStartDate = new Date(currentYear, quarterSeasonStartMonth, 1);
    // 获得本季度结束的日期
    const quarterSeasonEndDate = new Date(currentYear, quarterSeasonEndMonth, this.getMonthDays(currentYear, quarterSeasonEndMonth));
    // 返回
    return [quarterSeasonStartDate, quarterSeasonEndDate];
  },
  getMonthDays(year, month) {
    // 本月第一天 1-31
    const relativeDate = new Date(year, month, 1);
    // 获得当前月份0-11
    let relativeMonth = relativeDate.getMonth();
    // 获得当前年份4位年
    let relativeYear = relativeDate.getFullYear();
    // 当为12月的时候年份需要加1
    // 月份需要更新为0 也就是下一年的第一个月
    if (relativeMonth == 11) {
      relativeYear++;
      relativeMonth = 0;
    } else {
      // 否则只是月份增加,以便求的下一月的第一天
      relativeMonth++;
    }
    // 一天的毫秒数
    const millisecond = 1000 * 60 * 60 * 24;
    // 下月的第一天
    const nextMonthDayOne = new Date(relativeYear, relativeMonth, 1);
    // 返回得到上月的最后一天,也就是本月总天数
    return new Date(nextMonthDayOne.getTime() - millisecond).getDate();
  },
  getQuarterSeasonStartMonth(month) {
    const spring = 0; // 春
    const summer = 3; // 夏
    const fall = 6; // 秋
    const winter = 9; // 冬
    // 月份从0-11
    if (month < 3) {
      return spring;
    }
    if (month < 6) {
      return summer;
    }
    if (month < 9) {
      return fall;
    }

    return winter;
  },
  getvariableTime(data) {
    const type = Number(data.id);
    let s; let e; const a = new Date();
    switch (type) {
      case 1:/** 1 当天 */
        s = getToday();
        e = getToday();
        break;
      case 2:/** 2前一天 */
        s = getToday(a.addDate(-1));
        e = s;
        break;
      case 3:/** 3本周 */
        s = getToday(a.addDate(1 - a.getDay()));
        e = getToday(a.addDate(6));
        break;
      case 4:/** 4上周 */
        e = getToday(a.addDate(0 - a.getDay()));
        s = getToday(a.addDate(-6));
        break;
      case 5:/** 5本月 */
        s = getToday(a.addDate(1 - a.getDate()));
        e = getToday(a.addMonth(1).addDate(-1));
        break;
      case 6:/** 6上月 */
        e = getToday(a.addDate(0 - a.getDate()));
        s = getToday(a.addDate(1 - a.getDate()));
        break;
      case 7:/** 7本年 */
        // eslint-disable-next-line no-case-declarations
        const year7 = new Date().getFullYear();
        s = `${year7}-01-01`;
        e = `${year7}-12-31`;
        break;
      case 8:/** 8去年 */
        // eslint-disable-next-line no-case-declarations
        const year8 = (a.addYear(-1)).getFullYear();
        s = `${year8}-01-01`;
        e = `${year8}-12-31`;
        break;
      case 9:/** 9最近7天 */
        s = getToday(a.addDate(-6));
        e = getToday();
        break;
      case 10:/** 10最近30天 */
        e = getToday();
        s = getToday(a.addDate(-29));
        break;
      case 14:/** 本季 */
        return this.getCurrentSeason();
        break;
      case 23:/** 上季 */
        return this.getPreviousSeason();
        break;
      default:
        // eslint-disable-next-line no-case-declarations
        let vl;
        try {
          vl = data.valueList[0].value.split(",") || [];
        } catch (e) {
          console.log("全局变量格式无法解析：");
          console.log(data);
        }
        console.log(vl);
        return vl.map((v) => new Date(v));
    }
    if (s.length === 10) {
      s = `${s} 00:00:00`;
    }
    if (e.length === 10) {
      e = `${e} 23:59:59`;
    }
    if (e && s && (s.length + e.length) === 38) {
      return [new Date(s), new Date(e)];
    }
    return "";
  },
  /**
     * 数字格式转换成千分位
     *@param{Object}numZ
     */
  commafy(num) {
    if ((`${num}`).trim() === "") {
      return "";
    }
    if (isNaN(num)) {
      return "";
    }
    num += "";
    if (/^.*\..*$/.test(num)) {
      const pointIndex = num.lastIndexOf(".");
      let intPart = num.substring(0, pointIndex);
      const pointPart = num.substring(pointIndex + 1, num.length);
      intPart += "";
      const re = /(-?\d+)(\d{3})/;
      while (re.test(intPart)) {
        intPart = intPart.replace(re, "$1,$2");
      }
      num = `${intPart}.${pointPart}`;
    } else {
      num += "";
      const re = /(-?\d+)(\d{3})/;
      while (re.test(num)) {
        num = num.replace(re, "$1,$2");
      }
    }
    return num;
  },
  // 格式化
  dateFormatString(dateString, timeFormat) {
    if (dateString === undefined || dateString === null || dateString === "") {
      return "";
    }
    const formatMap = {
      1: "YYYY-MM",
      2: "YYYY/MM",
      3: "YYYYMM",
      4: "YYYY/MM/DD",
      5: "YYYY/MM/DD HH:mm",
      6: "YYYYMMDD",
      7: "YYYYMMDD HH:mm",
      8: "YYYY-MM-DD",
      9: "YYYY-MM-DD HH:mm",
      10: "YYYY-MM-DD HH:mm:ss",
      11: "YYYY-MM-DD HH",
      12: "YYYY"
    };
    const formatDate = formatMap[timeFormat];
    if (formatDate === undefined) {
      return dateString;
    }
    const dateStrings = dateString.split(",").filter(item => item.trim());
    if (dateString.length === 0) {
      return "";
    }
    if (dateStrings.length === 1) {
      return dayjs(dateStrings[0]).format(formatDate);
    }
    if (dateStrings.length === 2) {
      const sd = dateStrings[0] ? dayjs(dateStrings[0]).format(formatDate) : "";
      const ed = dateStrings[1] ? dayjs(dateStrings[1]).format(formatDate) : "";
      return `${sd}至${ed}`;
    }
  },
  // 单选 数据
  dependDataForSelect(colData, transferConfig, userInfo) {
    // 单独处理select 包括单选数据
    // 静态数据处理
    const dataSource = Number(colData.dataSource);
    const field = colData.field || colData.nameletiable;
    const templateId = colData.templateId;
    const dependData = { requestService: selectService }; // 引入服务名称
    if (field === "overtimeType") { // 加班套件
      dependData.requestFn = "queryRuleAttrData";// 服务中方法
      dependData.requestParams = { // 请求参数
        formId: templateId,
        fieldNameVariable: "overtimeType",
        fieldkey: "ruleName",
        busType: 2,
        id: 0
      };
    } else if (field === "leaveType") { // 请假套件
      dependData.requestFn = "queryVacationTypeData";// 服务中方法
      dependData.requestParams = { // 请求参数
        userId: userInfo.userId
      };
    } else if (field === "requestDept" || field === "requestOrg") { // 部门 组织机构
      dependData.requestFn = "bdQueryObjectTree";// 服务中方法
      dependData.requestParams = { // 请求参数
        objType: colData.areaObjType,
        id: 0
      };
      // dependData.requestFn = "mdQueryUserInfoByIds";// 服务中方法
      // dependData.requestParams = { // 请求参数
      //   id: ""
      // };
    } else if (dataSource === 2 || ["billStatus", "billStatusName", "enable", "vcStatus"].includes(field)) { // 自定义数据
      dependData.requestFn = "mdQueryLable";// 服务中方法
      dependData.requestParams = {
        templateId: colData.objTypeId || templateId,
        uuid: colData.uuid,
        businessType: "",
        field
      };
    } else if (!dataSource || dataSource === 1 || dataSource === 6) {
      // 业务建模启用状态dataSource为6
      if (field === "refResource") { // 基础数据 或者基础数据的来源
        dependData.requestFn = "mdQueryResourceBaseData";// 服务中方法
        dependData.requestParams = { // 请求参数
          templateId
        };
      } else if (colData.areaObjType === "002") { // 非级联的人员分支 只有type == 003 才做辅助选择
        dependData.choiceRule = false;
        dependData.requestFn = "bdQueryPersonDeptTree";// 服务中方法
        dependData.requestParams = { // 请求参数
          isNeedLogo: true,
          id: 0
        };
      } else {
        dependData.choiceRule = true;
        dependData.requestFn = "bdQueryObjectTree";// 服务中方法
        dependData.requestParams = { // 请求参数
          objectTypeId: colData.areaObjTypeId,
          objType: colData.areaObjType,
          id: 0
        };
      }
      dependData.requestParams.getDisable = 0; //查询停用基础数据4.9.3
    } else if (dataSource === 3) { // 固定取 dataArea (前人留下,看不懂 下面代码未验证正确性, 可以无视)
      if (colData.dataArea?.length) {
        transferConfig.modeType = "normal";
        dependData.dataSource = 11;
        dependData.dataList = colData.dataArea;
        dependData.requestService = null;
      } else if (colData.areaObjType && colData.areaObjType !== "000") {
        dependData.areaObjType = colData.areaObjType;
      }
    } else if (dataSource === 6) { //关联类型 业务建模
      // 业务建模 v3.7 chenjm 万江业务建模单选优化
      // 指定业务建模模板类型，指定单选字段
      return Utils.dependDataForSelect(colData.choiseField, transferConfig, userInfo);
    }
    return dependData;
  }
};

export default Utils;
